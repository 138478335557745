import React from "react";

import Logo from "../../../assets/images/micelu-acinco.png";

const Navbar = () => {
  return (
    <nav className={`fixed z-40 w-full bg-white`}>
      <div className="max-w-screen flex lg:justify-between">
        <div className="ml-10 flex w-full items-center justify-start px-5 lg:justify-center lg:px-0 xl:w-2/6 2xl:w-2/6">
          <div className="my-5 -ml-7 w-10 pt-5 lg:ml-0 lg:w-40 lg:pt-2">
            <img
              alt="micelu_acinco_logo"
              src={Logo}
              className="img-fluid w-full"
            />
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
