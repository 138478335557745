import { AxiosResponse } from "axios";

import { microserviceApiAxios } from "../../microservice-api.axios";
import { microserviceApiRoutes } from "../../microservice-api.routes";
import {
  ICreatePolicyAgentsReq,
  ICreatePolicyAgentsRes,
} from "./create-micelu-service.interface";

export const createPolicyAgent = (data: ICreatePolicyAgentsReq) => {
  const url = microserviceApiRoutes.v1.agents.policy.create();
  return microserviceApiAxios
    .post<Error, AxiosResponse<ICreatePolicyAgentsRes>>(url, data)
    .then((res) => res.data);
};
