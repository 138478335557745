import {
  UseQueryOptions,
  useQuery,
  QueryOptions,
  useMutation,
} from "react-query";
import {
  listDocumentsUploadTotalDamage,
  uploadDocumentsStatesClaims,
} from "./document-service";
import { IListDocumentsUploadTotalDamageRes } from "./document-service.interface";

export const useListDocumentsUploadTotalDamage = (
  stageID: string,
  config?: UseQueryOptions<any>,
) => {
  return useQuery<IListDocumentsUploadTotalDamageRes>(
    "claims/stage/documents",
    () => listDocumentsUploadTotalDamage({ stageID }),
    config,
  );
};

export const useUploadDocumentsStateClaims = (config?: QueryOptions) => {
  return useMutation(({ stageID, data }: { stageID: number; data: FormData }) =>
    uploadDocumentsStatesClaims({ stageID, data }).then((res) => res.data),
  );
};
