import React from "react";
import Navbar from "./components/navbar";
import CreateMiCeluComponent from "./create/create.component";
import CreateMiCeluProvider from "./create/provider/createMiCelu.provider";

const MiCeluPage = () => {
  return (
    <div className="h-screen overflow-auto bg-gradient-to-b from-white to-blueFinance 2xl:overflow-hidden">
      <Navbar />
      <section className="flex h-full w-full items-center justify-center p-0 pt-20">
        <div className="container w-full px-4 py-20 lg:px-20">
          <div className="mt-10 rounded-xl bg-white py-5 shadow-xl lg:px-10">
            <CreateMiCeluProvider>
              <CreateMiCeluComponent />
            </CreateMiCeluProvider>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MiCeluPage;
