import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import { IUploadForm, useStepContext } from "../stepper.provider";
import {
  useListDocumentsUploadTotalDamage,
  useUploadDocumentsStateClaims,
} from "../../../../services/microservice/claim/documents/use-documents";
import { toFormData } from "../../../../util/toFormData";
import PrimaryButton from "../../../buttons/PrimaryButton";
import { useModalContext } from "../../../../providers/modal/modal.provider";
import ModalHeader from "../../components/ModalHeader";
import ModalBody from "../../components/ModalBody";

interface ICustomStepProps {
  colorPrimary: string;
}

const LabelCheckbox = styled.label`
  > input[type="checkbox"]:checked + i {
    background: "#000000" !important;
  }
`;

type IFormInput = {
  confirm: boolean;
};

const SendUploadDocAditional = ({ colorPrimary }: ICustomStepProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();
  const { uploadDocForm, reclamation, setCurrentStep, setUploadDocForm } =
    useStepContext();
  const { setShow } = useModalContext();
  const { mutateAsync: UploadDocs, isLoading } =
    useUploadDocumentsStateClaims();
  const { data: Documents } = useListDocumentsUploadTotalDamage(
    String(reclamation.current_stage.stage_order_id),
    {
      refetchOnWindowFocus: false,
    },
  );

  const handlePreviusStep = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const formatSumbitData = (values: IUploadForm) => {
    const documentData = {};

    if (Object.keys(values.documents).length === 1) {
      Object.keys(values.documents).forEach((value) => {
        if (values.documents[value].length > 0) {
          _.set(documentData, "file_to_save", values.documents[value][0]);
        }
      });
    } else {
      Object.keys(values.documents).forEach((value) => {
        if (values.documents[value].length > 0) {
          _.set(documentData, String(value), values.documents[value][0]);
        }
      });
    }

    return {
      address: values.address,
      city: values.city.id,
      pickup: values.pickUp ? "True" : "False",
      ticketID: reclamation.ticket_id,
      ...documentData,
    };
  };

  const clearProcess = () => {
    setCurrentStep(2);
    setShow((prevState) => ({ ...prevState, visible: false }));
    setUploadDocForm(null);
  };

  const onSubmit = () => {
    const data = formatSumbitData(uploadDocForm);
    const formData = toFormData(data);
    UploadDocs({
      stageID: reclamation.current_stage.stage_order_id,
      data: formData,
    })
      .then((res) => {
        setShow((prevState) => ({
          ...prevState,
          visible: true,
          header: <ModalHeader success={true} />,
          content: (
            <ModalBody
              title="Documentos cargados exitosamente"
              message="Tus documentos necesarios fueron cargados exitosamente, estaremos validando los documentos y se modificara el estado. "
            />
          ),
          titleOnAccept: "Aceptar",
          withClass: "flex",
          showBtnAccept: true,
          onAccept: clearProcess,
        }));
      })
      .catch((err) => {
        setShow((prevState) => ({
          ...prevState,
          visible: true,
          header: <ModalHeader success={false} />,
          content: (
            <ModalBody
              title=" No fue posible cargar tus documentos"
              message="Acaba de ocurrir un problema, lo sentimos."
            />
          ),
          onAccept: clearProcess,
          titleOnAccept: "Aceptar",
          withClass: "flex",
          showBtnAccept: true,
        }));
      });
  };

  return (
    <section className="-ml-7 w-80 sm:ml-0 sm:w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        5. <span style={{ color: colorPrimary }}>Enviar</span>
      </h2>
      <div>
        <table className="text-gray-700 table w-full">
          <tbody>
            <tr>
              <td className="border-t-0">
                <h6 className="mb-4 mt-4 font-medium">Datos del dispositivo</h6>
              </td>
              <td className="border-t-0">&nbsp;</td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">
                Modelo del dispositivo:
              </td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {reclamation.device_name}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">
                IMEI del dispositivo:
              </td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {reclamation.imei}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">
                Línea Principal Asegurada:
              </td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {reclamation.phone_number}
              </td>
            </tr>
            <tr>
              <td className="border-t-0">
                <h6 className="mb-4 mt-4 font-medium">
                  Datos de la reclamación
                </h6>
              </td>
              <td className="border-t-0">&nbsp;</td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Plan</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {reclamation.plan_name}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Cobertura</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {reclamation.coverage_type}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Sponsor</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {reclamation.sponsor}
              </td>
            </tr>
            <tr>
              <td className="border-t-0">
                <h6 className="mb-4 mt-4 font-medium">Datos adicionales</h6>
              </td>
              <td className="border-t-0">&nbsp;</td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Ciudad</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {uploadDocForm.city.nombre} -{" "}
                {uploadDocForm.city.departamento.nombre}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Direccion</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {uploadDocForm.address}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">
                Solicita recogida
              </td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {uploadDocForm.pickUp ? "Si" : "No"}
              </td>
            </tr>
            <tr>
              <td className="border-t-0">
                <h6 className="mb-4 mt-4 font-medium">Documentos cargados</h6>
              </td>
              <td className="border-t-0">&nbsp;</td>
            </tr>
            {Documents.data.documents.map((doc) => (
              <tr key={doc.id}>
                <td className="border-b-0 border-t-0 pb-3 pt-1">
                  {doc?.nombre_documento_save}
                </td>
                <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                  {uploadDocForm.documents[doc.id][0]?.name}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <LabelCheckbox>
          <input
            {...register("confirm", {
              required: {
                value: true,
                message: "* Requerido",
              },
            })}
            id="confirm"
            name="confirm"
            type="checkbox"
            className="mt-5"
          />
          <i></i> Confirmas tus datos.
        </LabelCheckbox>
        <div className="mb-5 h-1">
          {errors && (
            <p className="my-0 py-0 text-sm text-critical">
              {errors.confirm?.message}
            </p>
          )}
        </div>
        <div className="mt-4 flex justify-between">
          <PrimaryButton
            title="Atras"
            className="rounded-2xl border px-5 text-textBlack"
            onClick={() => handlePreviusStep()}
            type="button"
          />

          <PrimaryButton
            onClick={handleSubmit(onSubmit)}
            title="Enviar"
            className="rounded-2xl bg-buttonBlack px-5 text-white"
            loading={isLoading}
            disabled={isLoading}
          />
        </div>
      </div>
    </section>
  );
};

export default SendUploadDocAditional;
