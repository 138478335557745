import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Input from "../../../../components/inputs/Input";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import Dropdown from "../../../../components/inputs/Dropdown";
import {
  ICitiesClaim,
  IGenderClaim,
} from "../../../../services/microservice/claim/types/claims";
import { listGenderInsurrance } from "../../../../services/microservice/claim/gender/gender-service";
import { listCityInsurrance } from "../../../../services/microservice/claim/city/city-service";
import DropdownFilter from "../../../../components/inputs/DropdownFilter";
import { useCreateMiCeluContext } from "../provider/createMiCelu.provider";

interface IInputs {
  name: string;
  lastName: string;
  gender: {
    id: number;
    name: string;
  };
  typeDocument: {
    name: string;
    value: string;
  };
  numberDocument: string;
  birthday: Date;
  email: string;
  phone: string;
  city: {
    id: number;
    nombre: string;
    departamento: {
      nombre: string;
    };
  };
  address: string;
}

const messageRequired = " Requerido";

const schemaValidation = yup.object().shape({
  name: yup.string().required(messageRequired),
  lastName: yup.string().required(messageRequired),
  gender: yup.object().required(messageRequired),
  typeDocument: yup.object().required(messageRequired),
  numberDocument: yup
    .string()
    .matches(/^\d+$/, { message: " Requerido" })
    .required(messageRequired),
  birthday: yup.date().required(messageRequired),
  email: yup.string().email().required(messageRequired),
  phone: yup
    .string()
    .matches(/^\d+$/, { message: " Requerido" })
    .required(messageRequired),
  city: yup.object().required(messageRequired),
  address: yup.string().required(messageRequired),
});

const UserDataMiCeluComponent = () => {
  const [genders, setGenders] = useState<IGenderClaim[]>();
  const [cities, setCities] = useState<ICitiesClaim[]>();
  const actualDate = new Date();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schemaValidation),
  });

  const { setStep, setClient, setInfoPlanDevice } = useCreateMiCeluContext();

  useEffect(() => {
    listGenderInsurrance().then((res) => setGenders(res.data.cities));
  }, []);
  useEffect(() => {
    listCityInsurrance().then((res) => setCities(res.data.cities));
  }, []);

  const onSubmit = (val: IInputs) => {
    setClient(val);
    setStep(5);
  };
  const handlePreviousStep = () => {
    setStep(3);
    setInfoPlanDevice(null);
  };

  return (
    <section className="w-full">
      <h2 className="mb-2 text-center text-3xl font-bold">
        <span className="text-buttonBlack">Información cliente</span>
      </h2>
      <div className="mb-7 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-5">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  classNameContainer="w-full mb-7"
                  classNameInput="w-full"
                  placeholder="Nombres cliente"
                  error={errors?.name?.message}
                  required
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  classNameContainer="w-full mb-7"
                  classNameInput="w-full"
                  placeholder="Apellidos cliente"
                  required
                  error={errors?.lastName?.message}
                />
              )}
            />
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <Dropdown
                  options={genders || []}
                  label1="name"
                  placeholder="Género"
                  required
                  error={errors?.gender?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="typeDocument"
              control={control}
              render={({ field }) => (
                <Dropdown
                  options={[
                    {
                      name: "Cédula de ciudadanía",
                      value: "CEDULA_CIUDADANIA",
                    },
                    {
                      name: "Cédula de extranjería",
                      value: "CEDULA_EXTRANJERIA",
                    },
                  ]}
                  label1="name"
                  placeholder="Tipo de documento"
                  error={errors?.typeDocument?.message}
                  required
                  {...field}
                />
              )}
            />
            <Controller
              name="numberDocument"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  classNameContainer="w-full mb-7"
                  classNameInput="w-full"
                  placeholder="Número de documento"
                  error={errors?.numberDocument?.message}
                  required
                />
              )}
            />
            <Controller
              name="birthday"
              control={control}
              render={({ field }) => (
                <div className="my-0 mb-7">
                  <div className="h-10">
                    <p className="my-0 py-0 text-base">
                      Fecha de nacimiento{" "}
                      <span className="text-3xl text-critical">*</span>{" "}
                      {errors && (
                        <span className="my-0 py-0 text-base text-critical">
                          {errors.birthday?.message}
                        </span>
                      )}
                    </p>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      sx={{
                        ".css-1ll44ll-MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0.5rem",
                          borderWidth: 0,
                        },
                        ".css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "10px 14px",
                          },
                      }}
                      className="w-full rounded-md border-0 bg-gray text-textBlack"
                      slotProps={{
                        textField: {
                          placeholder: "Fecha de nacimiento",
                        },
                      }}
                      maxDate={dayjs(actualDate)}
                      {...field}
                    />
                  </LocalizationProvider>
                </div>
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  classNameContainer="w-full mb-7"
                  classNameInput="w-full"
                  placeholder="Email"
                  error={errors?.email?.message}
                  required
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  classNameContainer="w-full mb-7"
                  classNameInput="w-full"
                  placeholder="Celular"
                  error={errors?.phone?.message}
                  required
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <DropdownFilter
                  options={cities || []}
                  label="nombre"
                  placeholder="Ciudad"
                  required
                  error={errors?.city?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  classNameContainer="w-full mb-7"
                  classNameInput="w-full"
                  placeholder="Dirección"
                  required
                  error={errors?.address?.message}
                />
              )}
            />
          </div>
          <div className="flex w-full justify-between">
            <PrimaryButton
              title="Atras"
              className="mt-5 w-36 rounded-3xl border px-5 text-textBlack"
              type="button"
              onClick={() => handlePreviousStep()}
            />
            <PrimaryButton
              title="Siguiente"
              className={
                "mt-5 w-36 rounded-3xl bg-buttonBlack px-5 py-1 text-sm font-normal text-whiteSoft"
              }
              type="submit"
            />
          </div>
        </form>
      </div>
    </section>
  );
};

export default UserDataMiCeluComponent;
