import React from "react";
import LoginStepComponent from "./components/login.component";
import DeviceMiCeluComponent from "./components/device.component";
import UserDataMiCeluComponent from "./components/user.data.component";
import { useCreateMiCeluContext } from "./provider/createMiCelu.provider";
import Code2FAStepComponent from "./components/code.component";
import ReviewComponentStep from "./components/review.component";

const CreateMiCeluComponent = () => {
  const { step } = useCreateMiCeluContext();
  return (
    <div className="mx-auto w-5/6 overflow-hidden pt-10 lg:w-3/4">
      {step === 1 && <LoginStepComponent />}
      {step === 2 && <Code2FAStepComponent />}
      {step === 3 && <DeviceMiCeluComponent />}
      {step === 4 && <UserDataMiCeluComponent />}
      {step === 5 && <ReviewComponentStep />}
    </div>
  );
};
export default CreateMiCeluComponent;
