import { useMutation, useQuery } from "react-query";

import {
  getManufacturersBySponsor,
  getModelsBySponsor,
  getPoliciesBySponsor,
  verifyDeviceBysponsor,
} from "./device-service";
import {
  IUseGetPoliciesDevicesBySponsorService,
  IUseVerifyDeviceBySponsorService,
} from "./use-device.interface";

export const useGetManufacturers = (sponsorID: string) => {
  return useQuery("devices/manufacturers", () =>
    getManufacturersBySponsor(sponsorID),
  );
};

export const useGetModels = (sponsorID: string) => {
  return useMutation((manufacturer: string) =>
    getModelsBySponsor({ manufacturer, sponsorID }),
  );
};

export const useGetPolicesDevicesBySponsor = () => {
  return useMutation((data: IUseGetPoliciesDevicesBySponsorService) =>
    getPoliciesBySponsor(data),
  );
};

export const useVerifyDeviceBySponsor = () => {
  return useMutation((data: IUseVerifyDeviceBySponsorService) =>
    verifyDeviceBysponsor(data),
  );
};
