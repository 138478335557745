import React, { useEffect } from "react";
import { Switch, Case, Default } from "react-if";
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import { useStepCreateEnelContext } from "../stepper.provider";
import { useCreateEnelContext } from "../../provider/createEnel.provider";
import { useModalContext } from "../../../../../providers/modal/modal.provider";
import ModalHeader from "../../components/ModalHeader";
import ModalBody from "../../../../../components/modalv2/components/ModalBody";
import { useLoginUser } from "../../../../../hooks/useLoginUser";
import { useCreateEnelService } from "../../../../../services/microservice/enel/create/use-create";
import ERRORS from "../../../components/errors";
import { useMobile } from "../../../../../hooks/useMobile";
import { useTouchIDInfoService } from "../../../../../services/microservice/enel/touchID/use-touchID";

const CreateStepComponent = () => {
  const { user, device, touchID, status, setDevice, setUser, setStatus } =
    useCreateEnelContext();
  const { setCurrentStep } = useStepCreateEnelContext();
  const { setShow } = useModalContext();
  const { logout } = useLoginUser();
  const { mutateAsync: create, isLoading } = useCreateEnelService();
  const { mutateAsync: getInfoTouchID, isLoading: isLoadingValidateTouch } =
    useTouchIDInfoService();

  const { isMobile } = useMobile();

  const handlePreviusStep = () => {
    setCurrentStep(4);
  };

  const handleMessageError = (err): string => {
    if (err === "El IMEI ya cuenta con una póliza para este sponsor") {
      return ERRORS.POLICY_CREATED;
    } else {
      return ERRORS.INTERNAL;
    }
  };

  const onSubmit = async () => {
    try {
      const res = await create({
        line: device?.line,
        sponsorID: "ENEL",
        imei: device?.imei,
        tac: device?.imei.slice(0, 8),
        deviceName: device?.key,
        touchID: touchID,
      });
      if (res) {
        setShow((prevState) => ({
          ...prevState,
          visible: true,
          header: <ModalHeader success={true} />,
          content: (
            <ModalBody
              title="Proceso exitoso"
              message={`Tu activación fue creada exitosamente`}
            />
          ),
        }));
        setCurrentStep(1);
        setDevice(null);
        setUser(null);
        logout();
      }
    } catch (error) {
      setShow((prevState) => ({
        ...prevState,
        visible: true,
        header: <ModalHeader success={false} />,
        content: (
          <ModalBody
            title="Ocurrio un error"
            message={handleMessageError(error?.response?.data.error.message)}
          />
        ),
      }));
    }
  };

  const handleFinish = () => {
    setCurrentStep(1);
    setDevice(null);
    setUser(null);
    logout();
  };

  useEffect(() => {
    if (isMobile) {
      const createPolicy = async () => {
        const res = await getInfoTouchID({
          sponsorID: "ENEL",
          touchID: touchID,
        });
        if (res.data.isValidTouch) {
          create({
            line: device?.line,
            sponsorID: "ENEL",
            imei: device?.imei,
            tac: device?.imei.slice(0, 8),
            deviceName: device?.key,
            touchID: touchID,
          })
            .then((res) => {
              if (res) {
                setStatus("Protegido");
              }
            })
            .catch((error) => {
              setStatus(
                handleMessageError(error?.response?.data.error.message),
              );
            });
        } else {
          setStatus(ERRORS.ERROR_TOUCH);
        }
      };
      createPolicy();
    }
  }, []);

  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        5. <span style={{ color: "#461E7D" }}>Proteger</span> dispositivo
      </h2>
      <Switch>
        <Case condition={isLoading}>
          <div className="animate-pulse">
            <div className="flex-1 space-y-6 py-1">
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2 h-10 rounded bg-background"></div>
                  <div className="col-span-1 h-10 rounded bg-background"></div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2 h-10 rounded bg-background"></div>
                  <div className="col-span-1 h-10 rounded bg-background"></div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2 h-10 rounded bg-background"></div>
                  <div className="col-span-1 h-10 rounded bg-background"></div>
                </div>
              </div>
            </div>
          </div>
        </Case>
        <Case condition={isLoadingValidateTouch}>
          <div className="animate-pulse">
            <div className="flex-1 space-y-6 py-1">
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2 h-10 rounded bg-background"></div>
                  <div className="col-span-1 h-10 rounded bg-background"></div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2 h-10 rounded bg-background"></div>
                  <div className="col-span-1 h-10 rounded bg-background"></div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2 h-10 rounded bg-background"></div>
                  <div className="col-span-1 h-10 rounded bg-background"></div>
                </div>
              </div>
            </div>
          </div>
        </Case>
        <Default>
          <div>
            <table className="text-gray-700 table w-full">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="border-t-0">
                    <h6 className="mb-4 font-medium">Datos personales</h6>
                  </td>
                  <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                    &nbsp;
                  </td>
                </tr>
                <tr>
                  <td
                    className="border-b-0 border-t-0 pb-3 pt-1"
                    style={{ width: "10px" }}
                  >
                    Nombre(s):
                  </td>
                  <td
                    className="border-b-0 border-t-0 pb-3 pt-1 text-end"
                    style={{ width: "10px" }}
                  >
                    {user?.name}
                  </td>
                </tr>
                <tr>
                  <td className="border-b-0 border-t-0 pb-3 pt-1">
                    Apellido(s):
                  </td>
                  <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                    {user?.second_name}
                  </td>
                </tr>
                <tr>
                  <td className="border-b-0 border-t-0 pb-3 pt-1">Email:</td>
                  <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                    {user?.email}
                  </td>
                </tr>
                <tr>
                  <td className="border-b-0 border-t-0 pb-3 pt-1">
                    Número de celular:
                  </td>
                  <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                    {device?.line}
                  </td>
                </tr>
                <tr>
                  <td className="border-t-0">
                    <h6 className="mb-4 mt-4 font-medium">
                      Datos del dispositivo
                    </h6>
                  </td>
                  <td className="border-t-0">&nbsp;</td>
                </tr>
                <tr>
                  <td className="border-b-0 border-t-0 pb-3 pt-1">
                    Modelo del dispositivo:
                  </td>
                  <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                    {device?.key}
                  </td>
                </tr>
                <tr>
                  <td className="border-b-0 border-t-0 pb-3 pt-1">
                    Fabricante:
                  </td>
                  <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                    {device?.brand}
                  </td>
                </tr>
                <tr>
                  <td className="border-b-0 border-t-0 pb-3 pt-1">IMEI:</td>
                  <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                    {device?.imei}
                  </td>
                </tr>
                <tr>
                  <td className="border-t-0">
                    <h6 className="mb-4 mt-4 font-medium">Datos del plan</h6>
                  </td>
                  <td className="border-t-0">&nbsp;</td>
                </tr>
                <tr>
                  <td className="border-b-0 border-t-0 pb-3 pt-1">Nombre:</td>
                  <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                    {user?.plan_name}
                  </td>
                </tr>
                {isMobile && (
                  <tr>
                    <td className="border-b-0 border-t-0 pb-3 pt-1">Estado</td>
                    <td
                      className={`${status === "Protegido" ? "text-primary" : "text-critical"} border-b-0 border-t-0 pb-3 pt-1 text-end`}
                    >
                      {status}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="mt-4 flex justify-between">
              <PrimaryButton
                title="Atras"
                className="rounded-2xl border px-5 text-textBlack"
                type="button"
                style={{ borderColor: "#461E7D" }}
                onClick={() => handlePreviusStep()}
              />
              {isMobile ? (
                <PrimaryButton
                  type="button"
                  title="Aceptar"
                  className="rounded-2xl px-5 text-white"
                  style={{ backgroundColor: "#461E7D" }}
                  onClick={() => handleFinish()}
                />
              ) : (
                <PrimaryButton
                  type="button"
                  title="Aceptar"
                  className="rounded-2xl px-5 text-white"
                  style={{ backgroundColor: "#461E7D" }}
                  onClick={() => onSubmit()}
                  loading={isLoading}
                />
              )}
            </div>
          </div>
        </Default>
      </Switch>
    </section>
  );
};

export default CreateStepComponent;
