export const microserviceApiRoutes = {
  v1: {
    claim: {
      auth: {
        login: () => "/api/v1/claim/auth",
        me: () => "/api/v1/claim/user",
      },
      device: {
        list: () => `/api/v1/claim/device`,
      },
      plan: {
        list: (deviceID: string | number | undefined) =>
          `/api/v1/claim/plan/${deviceID}`,
      },
      coverage: {
        list: (planId: string | number | undefined) =>
          `/api/v1/claim/coverage/${planId}`,
      },
      documents: {
        list: (coverageId: string | number | undefined) =>
          `/api/v1/claim/documents/${coverageId}`,
        uploadTotalDamage: (stageID: string) =>
          `/api/v1/claim/stage/documents/list/${stageID}`,
        uploadDocuments: (stageID: number) =>
          `/api/v1/claim/stage/documents/upload/${stageID}`,
      },
      create: () => "/api/v1/claim/create",
      code: {
        generate: () => "/api/v1/claim/generate-code",
        validate: () => "/api/v1/claim/validate-code",
      },
      list: () => `/api/v1/claim/list`,
      states: (reclamationID: number) => `/api/v1/claim/state/${reclamationID}`,
    },
    shared: {
      gender: {
        list: () => "/api/v1/shared/gender",
      },
      city: {
        list: () => "/api/v1/shared/cities",
      },
    },
    advisory: {
      create: () => "/api/v1/acinco/advisory",
    },
    enel: {
      auth: () => "/api/v1/acinco/auth/login",
      me: () => "/api/v1/acinco/enel/me",
      imei: () => "/api/v1/acinco/enel/imei",
      touchID: () => "/api/v1/acinco/enel/touchID",
      infoTouchID: () => "/api/v1/acinco/enel/info/touchID",
      create: () => "/api/v1/acinco/enel/create",
      recovery: () => "/api/v1/acinco/enel/recovery",
    },
    devices: {
      models: {
        get: () => "/api/v1/acinco/devices/models",
      },
      manufacturers: {
        get: () => "/api/v1/acinco/devices/manufacturers",
      },
      policies: {
        get: () => "/api/v1/acinco/devices/policies",
      },
      verify: () => "/api/v1/acinco/devices/verify",
    },
    agents: {
      auth: {
        login: () => "/api/v1/acinco/auth/agents/login",
      },
      policy: {
        create: () => "/api/v1/acinco/agents/create/policy",
      },
    },
  },
};
