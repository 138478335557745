import React from "react";

interface IModalBodyProps {
  email: string;
}

const ModalBodyRecovery = ({ email }: IModalBodyProps) => {
  return (
    <div className="w-full justify-center">
      <p className="mx-10 my-5 text-center text-xl text-textBlack">
        Se ha enviado un correo electrónico a su correo registrado por enel, el
        cual es el siguiente:
        <br />
        <span className="font-bold">{email}</span>
      </p>
    </div>
  );
};

export default ModalBodyRecovery;
