import React from "react";

import Img1 from "../../../assets/images/step_1_img.png";
import Img2 from "../../../assets/images/step_2_img.png";
import Img3 from "../../../assets/images/step_3_img.png";
import Img4 from "../../../assets/images/step_4_img.png";
import Img5 from "../../../assets/images/step_5_img.png";
import { useStepContext } from "./stepper.provider";
import clsx from "clsx";
import { useModalContext } from "../../../providers/modal/modal.provider";
import ModalHeaderDocs from "../components/ModalHeaderDocs";
import ModalBodyDocs from "../components/ModalBodyDocs";

const steps = [
  {
    image: Img1,
    stepNumber: 1,
    title: "",
    titleBold: "Identifícate",
    type: "general",
  },
  /*  {
    image: Img2,
    stepNumber: 2,
    title: "o inicia una reclamación.",
    titleBold: "Consulta ",
    type: "general",
  }, */
  {
    image: Img2,
    stepNumber: 2,
    title: "tu cobertura",
    titleBold: "Selecciona",
    type: "claim",
  },
  {
    image: Img3,
    stepNumber: 3,
    title: "tus documentos",
    titleBold: "Sube",
    type: "claim",
  },
  {
    image: Img4,
    stepNumber: 4,
    title: "adicional",
    titleBold: "Información",
    type: "claim",
  },
  {
    image: Img5,
    stepNumber: 5,
    title: "",
    titleBold: "Enviar",
    type: "claim",
  },
  {
    image: Img4,
    stepNumber: 3,
    title: " de reclamación",
    titleBold: "Estado",
    type: "states",
  },
  {
    image: Img4,
    stepNumber: 4,
    title: " para cargar",
    titleBold: "Información",
    type: "states",
  },
  {
    image: Img5,
    stepNumber: 5,
    title: "",
    titleBold: "Enviar",
    type: "states",
  },
];

interface ICustomStepper {
  colorPrimary: string;
}

const StepperHeader = ({ colorPrimary }: ICustomStepper) => {
  const { currentStep, flowType } = useStepContext();
  const { setShow } = useModalContext();

  const onCurrentStep = (step: number): string => {
    if (currentStep < step) {
      return "opacity-50";
    } else {
      return "";
    }
  };

  const handleOpenRequiredDocument = () => {
    setShow((prevState) => ({
      ...prevState,
      visible: true,
      header: <ModalHeaderDocs />,
      content: <ModalBodyDocs />,
    }));
  };

  const changeLayoutByFlow = (flow: string) => {
    switch (flow) {
      case "claim":
        return "lg:grid-cols-5";
      case "states":
        return "lg:grid-cols-5";
      default:
        return "lg:grid-cols-2";
    }
  };
  return (
    <section className="w-full p-2 lg:p-10">
      <div className="lg:px-5">
        <p className="text-center lg:my-3">
          Antes de realizar tu reclamación revisa los documentos necesarios,
          revísalos{" "}
          <button
            type="button"
            className="hover:underline hover:underline-offset-4"
            onClick={() => handleOpenRequiredDocument()}
          >
            aquí.
          </button>
        </p>
        <div
          className={`hidden lg:mx-10 lg:my-5 lg:grid ${changeLayoutByFlow(flowType)} lg:gap-2`}
        >
          {steps.map(
            ({ image, title, titleBold, stepNumber, type }) =>
              (flowType === type || type === "general") && (
                <div
                  key={stepNumber}
                  className={clsx([
                    "my-10 flex flex-col items-center align-middle",
                    onCurrentStep(stepNumber),
                  ])}
                >
                  <img
                    src={image}
                    alt={`step_${stepNumber}_img`}
                    className="img-fluid hidden bg-transparent lg:block"
                  />
                  <h3 className="mt-2 text-center text-xl font-bold">
                    {stepNumber}.{" "}
                    <span style={{ color: colorPrimary }}>{titleBold}</span>{" "}
                    {title}
                  </h3>
                </div>
              ),
          )}
        </div>
      </div>
    </section>
  );
};

export default StepperHeader;
