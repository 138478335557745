import { UseQueryOptions, useQuery } from "react-query";
import { getStatesClaim } from "./state-service";
import { IGetStatesClaimRes } from "./state-service.interface";

export const useGetStatesClaim = (
  reclamationID: number,
  config?: UseQueryOptions<any>,
) => {
  return useQuery<IGetStatesClaimRes>(
    "claim/state",
    () => getStatesClaim({ reclamationID }),
    config,
  );
};
