import React from "react";
import { useStepContext } from "./stepper.provider";
import LoginStepComponent from "./steps/login.step.component";
import PlanStepComponent from "./steps/plan.step.component";
import DocumentStepComponent from "./steps/document.step.component";
import AditionalStepComponent from "./steps/aditional.step.component";
import SendStepComponet from "./steps/send.step.component";
import ListClaimsStepComponent from "./steps/list-claims.step.component";
import StatesStepComponent from "./steps/states.step.component";
import UploadStatesDocumentsStep from "./steps/upload-states-documents.component";
import SendUploadDocAditional from "./steps/send-upload-doc-aditional.step.component";

interface ICustomStepper {
  colorPrimary: string;
  privacyPolicy?: React.ReactNode;
  dobleAuth?: boolean;
}

const StepperBody = ({
  colorPrimary,
  privacyPolicy,
  dobleAuth,
}: ICustomStepper) => {
  const { currentStep, flowType } = useStepContext();
  const firstStep = (
    <LoginStepComponent
      colorPrimary={colorPrimary}
      privacyPolicy={privacyPolicy}
    />
  );
  const thirdStepClaims = (
    <PlanStepComponent colorPrimary={colorPrimary} dobleAuth={dobleAuth} />
  );
  const fourthStepClaims = (
    <DocumentStepComponent colorPrimary={colorPrimary} dobleAuth={dobleAuth} />
  );
  const fiveStepClaims = (
    <AditionalStepComponent colorPrimary={colorPrimary} dobleAuth={dobleAuth} />
  );
  const sixStepClaims = (
    <SendStepComponet colorPrimary={colorPrimary} dobleAuth={dobleAuth} />
  );
  const secondStep = <ListClaimsStepComponent colorPrimary={colorPrimary} />;
  const thirdStepStates = <StatesStepComponent colorPrimary={colorPrimary} />;
  const thirdStepAditionalUploadDoc = (
    <UploadStatesDocumentsStep colorPrimary={colorPrimary} />
  );
  const fourthStepAditionalUploadDoc = (
    <SendUploadDocAditional colorPrimary={colorPrimary} />
  );
  return (
    <section className="mx-auto w-4/5 border-t pt-10 lg:w-2/4">
      <div className="mx-auto">
        {currentStep === 1 && firstStep}
        {/*  {currentStep === 2 && secondStep} */}
        {flowType === "claim" && (
          <>
            {currentStep === 2 && thirdStepClaims}
            {currentStep === 3 && fourthStepClaims}
            {currentStep === 4 && fiveStepClaims}
            {currentStep === 5 && sixStepClaims}
          </>
        )}
        {flowType === "states" && (
          <>
            {currentStep === 3 && thirdStepStates}
            {currentStep === 4 && thirdStepAditionalUploadDoc}
            {currentStep === 5 && fourthStepAditionalUploadDoc}
          </>
        )}
      </div>
    </section>
  );
};

export default StepperBody;
