import { useMutation, QueryOptions, useQuery } from "react-query";

import {
  loginEnelService,
  getMeEnelService,
  recoveryEmailService,
} from "./auth-service";
import {
  IGetMeService,
  IRecoveryService,
  IUseLoginService,
} from "./user-auth.interface";

export const useLoginEnelService = (config?: QueryOptions) => {
  return useMutation(
    (data: IUseLoginService) => loginEnelService(data).then((res) => res),
    config,
  );
};

export const useGetMeEnelService = (config?: QueryOptions<any>) => {
  return useQuery<IGetMeService>("enel/me", getMeEnelService, config);
};

export const useRecoveryEmailService = (config?: QueryOptions) => {
  return useMutation(
    (data: IRecoveryService) => recoveryEmailService(data).then((res) => res),
    config,
  );
};
