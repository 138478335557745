import { AxiosResponse } from "axios";

import { microserviceApiAxios } from "../../microservice-api.axios";
import { microserviceApiRoutes } from "../../microservice-api.routes";
import {
  IGetStatesClaimReq,
  IGetStatesClaimRes,
} from "./state-service.interface";

export const getStatesClaim = (params: IGetStatesClaimReq) => {
  const { reclamationID } = params;
  const url = microserviceApiRoutes.v1.claim.states(reclamationID);
  return microserviceApiAxios
    .get<Error, AxiosResponse<IGetStatesClaimRes>>(url)
    .then((res) => res.data);
};
