import React from "react";
import { useStepContext } from "../stepper.provider";
import { useQueryClient } from "react-query";
import { Unless, When } from "react-if";
import { Skeleton, Tooltip } from "@mui/material";
import {
  CheckCircle,
  RadioButtonChecked,
  HelpOutline,
} from "@mui/icons-material";

import { useGetStatesClaim } from "../../../../services/microservice/claim/state/use-getState";
import PrimaryButton from "../../../buttons/PrimaryButton";

interface ICustomStepProps {
  colorPrimary: string;
}

const StatesStepComponent = ({ colorPrimary }: ICustomStepProps) => {
  const { setCurrentStep, setFlowType, reclamation } = useStepContext();
  const { data, isLoading } = useGetStatesClaim(reclamation.ticket_id, {
    refetchOnWindowFocus: false,
    retry: 2,
  });
  const queryClient = useQueryClient();

  const handlePreviusStep = () => {
    setCurrentStep((prevState) => prevState - 1);
    queryClient.removeQueries("claim/state");
  };

  const handleNextStep = () => {
    setFlowType("general");
    setCurrentStep(1);
  };
  const handlePressButton = () => {
    setCurrentStep((prevState) => prevState + 1);
  };
  return (
    <section>
      <h2 className="mb-10 text-center text-3xl font-bold">
        3.{" "}
        <span
          style={{
            color: colorPrimary,
          }}
        >
          Estado
        </span>{" "}
        de reclamación
      </h2>
      <div className="mb-14 h-auto min-h-80 w-full">
        <When condition={!isLoading}>
          {data?.data.states.map((state, ind) => (
            <div
              key={`${state.name}_${ind}`}
              className="mb-5 flex w-full items-center justify-between"
            >
              <div className="flex items-center">
                {!state.inProcess && !state.isFinished && (
                  <CheckCircle className="text-primary" fontSize={"large"} />
                )}
                {state.inProcess && !state.isFinished && (
                  <RadioButtonChecked
                    style={{ color: "#FFD422" }}
                    fontSize={"large"}
                  />
                )}
                {!state.inProcess && state.isFinished && (
                  <CheckCircle className="text-primary" fontSize={"large"} />
                )}

                <p className="text-light ml-5 text-lg">{state.name}</p>
              </div>
              <Tooltip title={state.text}>
                <HelpOutline className="text-secondary" />
              </Tooltip>
            </div>
          ))}
        </When>
        <Unless condition={!isLoading}>
          <Skeleton variant="text" width={"100%"} height={"14rem"} />
        </Unless>
      </div>
      <div className="my-5 flex justify-between">
        <PrimaryButton
          title="Atras"
          className="rounded-2xl border px-5 text-textBlack"
          onClick={() => handlePreviusStep()}
          type="button"
          style={{ borderColor: "#461E7D" }}
        />
        <When condition={reclamation?.current_stage.user_action_required}>
          <PrimaryButton
            type="button"
            title="Cargar documentos"
            className="rounded-2xl bg-buttonBlack px-5 text-white"
            style={{ backgroundColor: colorPrimary }}
            onClick={() => handlePressButton()}
          />
        </When>
        <Unless condition={reclamation?.current_stage.user_action_required}>
          <PrimaryButton
            type="button"
            title="Cerrar sesión"
            className="rounded-2xl bg-buttonBlack px-5 text-white"
            onClick={handleNextStep}
          />
        </Unless>
      </div>
    </section>
  );
};

export default StatesStepComponent;
