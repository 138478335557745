import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { formatValue } from "react-currency-input-field";

import Input from "../../../../components/inputs/Input";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import {
  useGetManufacturers,
  useGetModels,
  useGetPolicesDevicesBySponsor,
  useVerifyDeviceBySponsor,
} from "../../../../services/microservice/micelu/device/use-device";
import DropdownFilter from "../../../../components/inputs/DropdownFilter";
import {
  IModel,
  IPolicies,
} from "../../../../services/microservice/micelu/device/device-service.interface";
import Dropdown from "../../../../components/inputs/Dropdown";
import { useCreateMiCeluContext } from "../provider/createMiCelu.provider";

const schemaValidation = yup.object({
  imei: yup
    .string()
    .matches(/^\d+$/, { message: " Requerido" })
    .required(" Requerido"),
  insuredValue: yup.string().required(" Requerido"),
  manufacturer: yup
    .object({
      id_system: yup.string().required(" Requerido"),
    })
    .required(" Requerido"),
  model: yup.object().required(" Requerido").typeError(" Requerido"),
  plan: yup.mixed().required(" Requerido"),
  valuePolicie: yup.string().optional(),
});

const DeviceMiCeluComponent = () => {
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schemaValidation),
  });
  const [models, setModels] = useState<IModel[]>();
  const [error, setError] = useState<string>("");
  const [infoPolicies, setInfoPolicies] = useState<IPolicies[] | null>(null);
  const { data: Manufacturers } = useGetManufacturers("MICELU");
  const { mutateAsync: getModels } = useGetModels("MICELU");
  const { mutateAsync: getPolicies } = useGetPolicesDevicesBySponsor();
  const { setStep, setInfoPlanDevice } = useCreateMiCeluContext();
  const { mutateAsync: verifyDevice, isLoading } = useVerifyDeviceBySponsor();

  useEffect(() => {
    if (watch("manufacturer") || watch("imei") || watch("insuredValue")) {
      setValue("model", "");
      clearErrors("plan");
      setInfoPolicies(null);
      const manufacturer = watch("manufacturer")?.id_system;
      getModels(manufacturer).then((res) => setModels(res.data.models));
    } else {
      setInfoPolicies(null);
      setValue("model", "");
      setModels([]);
    }
  }, [watch("manufacturer"), watch("imei"), watch("insuredValue")]);

  const getPlansDevices = async (values) => {
    try {
      const res = await getPolicies({
        imei: values.imei,
        tac: values.model.reference_tac,
        insuredValue: values.insuredValue?.replace(/,/g, "").replace("$", ""),
        manufacturer: values.manufacturer.nombre,
        model: values.model.device_name,
        sponsorID: "MICELU",
      });
      if (res) {
        setValue("plan", "" as any);
        setValue("valuePolicie", "");
        setInfoPolicies(res.data.policies);
        setError("");
      }
    } catch (error) {
      if (error.status === 400) {
        setError(error?.response?.data?.error?.message);
      } else {
        setError("Ocurrio un error inesperado");
      }
    }
  };

  const onSubmit = async (values) => {
    try {
      const res = await verifyDevice({
        imei: values.imei,
        manufacturer: values.manufacturer.nombre,
        model: values.model.device_name,
      });
      if (res) {
        handleNextStep({
          ...values,
          insuredValue: values.insuredValue?.replace(/,/g, "").replace("$", ""),
        });
      }
    } catch (error) {
      if (error.status === 400) {
        setError(error?.response?.data?.error?.message);
      } else {
        setError("Ocurrio un error inesperado");
      }
    }
  };

  useEffect(() => {
    if (watch("plan")) {
      const plan = watch("plan") as any;
      if (!_.isEmpty(plan)) {
        setValue(
          "valuePolicie",
          `$ ${plan.pricingOptions[0].paymentAmountWithTaxes
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`,
        );
      }
    }
  }, [watch("plan")]);

  useEffect(() => {
    if (
      watch("insuredValue") !== "" &&
      watch("manufacturer") !== null &&
      watch("model") !== null &&
      watch("model") !== "" &&
      watch("insuredValue") !== undefined
    ) {
      getPlansDevices({
        imei: "",
        model: watch("model"),
        manufacturer: watch("manufacturer"),
        insuredValue: watch("insuredValue"),
      });
    } else {
      setValue("plan", "" as any);
      setValue("valuePolicie", "");
      clearErrors("plan");
      setInfoPolicies(null);
    }
  }, [watch("model"), watch("insuredValue"), watch("manufacturer")]);

  useEffect(() => {
    if (watch("insuredValue") === "$") {
      setValue("insuredValue", "");
    }
  }, [watch("insuredValue")]);

  const handleNextStep = (val) => {
    setStep(4);
    setInfoPlanDevice(val);
  };

  const handlePreviousStep = () => {
    setStep(1);
  };

  const displayCurrency = (val: string) => {
    let formatCurrency = "";
    const currency = val?.replace(/,/g, "").replace("$", "");
    if (val !== "" && val !== "$Na" && val !== "$NaN" && val !== "$") {
      formatCurrency = formatValue({
        value: currency,
        groupSeparator: ",",
        decimalSeparator: ".",
        prefix: "$",
      });
    } else {
      return "";
    }
    return formatCurrency;
  };

  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        <span className="text-buttonBlack">Información plan y dispositivo</span>
      </h2>
      <div className="mb-10 w-full">
        <form>
          <div className="grid grid-cols-2 gap-10">
            <Controller
              name="imei"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  classNameContainer="w-full mb-7"
                  classNameInput="w-full"
                  placeholder="IMEI"
                  required
                  error={errors?.imei?.message}
                />
              )}
            />
            <Controller
              name="insuredValue"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  classNameContainer="w-full mb-7"
                  classNameInput="w-full"
                  placeholder="Valor cubierto"
                  required
                  error={errors?.insuredValue?.message}
                  value={displayCurrency(watch("insuredValue"))}
                />
              )}
            />
            <Controller
              name="manufacturer"
              control={control}
              render={({ field }) => (
                <DropdownFilter
                  {...field}
                  options={Manufacturers?.data?.manufacturers || []}
                  placeholder="Selecciona la marca"
                  label="nombre"
                  city={false}
                  required
                  error={errors?.manufacturer?.id_system?.message}
                />
              )}
            />
            <Controller
              name="model"
              control={control}
              render={({ field }) => (
                <DropdownFilter
                  {...field}
                  options={models || []}
                  placeholder="Selecciona el modelo"
                  label="device_name"
                  city={false}
                  required
                  error={errors?.model?.message}
                />
              )}
            />

            <Controller
              name="plan"
              control={control}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  placeholder="Plan"
                  required
                  options={
                    infoPolicies?.filter(
                      (d) =>
                        d.name !== "Asistencia Fractura de Pantalla Micelu",
                    ) || []
                  }
                  label1="name"
                  error={errors?.plan?.message}
                />
              )}
            />
            <Controller
              name="valuePolicie"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  classNameContainer="w-full mb-7"
                  classNameInput="w-full"
                  placeholder="Valor cobertura"
                  readOnly
                  error={errors?.valuePolicie?.message}
                />
              )}
            />
            {error && <p className="text-md text-critical">{error}</p>}
          </div>
          <div className="flex w-full justify-between">
            <PrimaryButton
              title="Atras"
              className="mt-5 w-36 rounded-3xl border px-5 text-textBlack"
              type="button"
              onClick={() => handlePreviousStep()}
            />

            <PrimaryButton
              title="Siguiente"
              onClick={handleSubmit(onSubmit)}
              className={
                "mt-5 w-36 rounded-3xl bg-buttonBlack px-5 py-1 text-sm font-normal text-whiteSoft"
              }
              loading={isLoading}
            />
          </div>
        </form>
      </div>
    </section>
  );
};

export default DeviceMiCeluComponent;
