import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { Claim } from "../../../services/microservice/claim/list/list-service.interface";
import {
  ICitiesClaim,
  IDocumentsCoveragesClaim,
} from "../../../services/microservice/claim/types/claims";

type IContext = {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  flowType: string;
  setFlowType: Dispatch<SetStateAction<string>>;
  reclamation: Claim | null;
  setReclamation: Dispatch<SetStateAction<Claim | null>>;
  uploadDocForm: IUploadForm | null;
  setUploadDocForm: Dispatch<SetStateAction<IUploadForm | null>>;
};

interface IProps {
  children: ReactNode;
}

export interface IUploadForm {
  city: ICitiesClaim;
  address: string;
  pickUp: boolean;
  documents: any;
}

export const StepContext = React.createContext<IContext>({} as IContext);
export const useStepContext = () => useContext<IContext>(StepContext);

const StepProvider = ({ children }: IProps) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [flowType, setFlowType] = useState<string>("claim");
  const [reclamation, setReclamation] = useState<Claim | null>(null);
  const [uploadDocForm, setUploadDocForm] = useState<IUploadForm | null>(null);

  const context: IContext = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      flowType,
      setFlowType,
      reclamation,
      setReclamation,
      uploadDocForm,
      setUploadDocForm,
    }),
    [currentStep, flowType, reclamation, uploadDocForm],
  );

  return (
    <StepContext.Provider value={context}>{children}</StepContext.Provider>
  );
};

export default StepProvider;
