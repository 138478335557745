import { AxiosResponse } from "axios";

import { microserviceApiAxios } from "../../microservice-api.axios";
import { microserviceApiRoutes } from "../../microservice-api.routes";
import {
  IAuthAgentsLoginReq,
  IAuthAgentLoginRes,
} from "./auth-micelu-service.interface";

export const authLoginMicelu = (data: IAuthAgentsLoginReq) => {
  const url = microserviceApiRoutes.v1.agents.auth.login();
  return microserviceApiAxios
    .post<Error, AxiosResponse<IAuthAgentLoginRes>>(url, data)
    .then((res) => res.data);
};
