import React from "react";
import { useCreateMiCeluContext } from "../provider/createMiCelu.provider";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { useCreatePolicyAgents } from "../../../../services/microservice/micelu/create/use-create-micelu";
import { useModalContext } from "../../../../providers/modal/modal.provider";
import ModalHeader from "../../../../components/claims/components/ModalHeader";
import ModalBody from "../../../../components/claims/components/ModalBody";

const ReviewComponentStep = () => {
  const { client, infoPlanDevice, setStep, setClient, setInfoPlanDevice } =
    useCreateMiCeluContext();
  const { mutateAsync: create, isLoading } = useCreatePolicyAgents();
  const { setShow } = useModalContext();

  const organizeGender = (gender: string) => {
    if (gender.includes(" ")) {
      return gender.replace(" ", "_").toUpperCase();
    }
    return gender.toUpperCase();
  };

  const clearProcess = () => {
    setClient(null);
    setInfoPlanDevice(null);
    setStep(3);
    setShow((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  const onSubmit = async () => {
    const data = {
      client: {
        firstName: client.name,
        lastName: client.lastName,
        email: client.email,
        genderId: organizeGender(client.gender.name),
        identification: {
          type: client.typeDocument.value,
          number: client.numberDocument,
        },
        address: client.address,
      },
      planId: infoPlanDevice.plan.id,
      device: {
        referenceTac: infoPlanDevice.model.reference_tac,
        imei: infoPlanDevice.imei,
        line: client.phone,
      },
      sponsorId: "MICELU",
      priceOptionId: infoPlanDevice.plan.pricingOptions[0].id,
      insuredValue: infoPlanDevice.insuredValue,
    };
    try {
      const res = await create(data);
      if (res) {
        setShow((prevState) => ({
          ...prevState,
          visible: true,
          header: <ModalHeader success={true} />,
          content: (
            <ModalBody
              title="Creación póliza exitosa"
              message={`La póliza se creo exitosamente con id: ${res.data.policy.id}`}
            />
          ),
          onAccept: clearProcess,
          titleOnAccept: "Aceptar",
          withClass: "flex",
          showBtnAccept: true,
        }));
      }
    } catch (error) {
      if (error.response.status === 400) {
        setShow((prevState) => ({
          ...prevState,
          visible: true,
          header: <ModalHeader success={false} />,
          content: (
            <ModalBody
              title=" No fue posible crear la póliza"
              message={error?.response.data.error.message}
            />
          ),
          onAccept: clearProcess,
          titleOnAccept: "Aceptar",
          withClass: "flex",
          showBtnAccept: true,
        }));
      } else {
        setShow((prevState) => ({
          ...prevState,
          visible: true,
          header: <ModalHeader success={false} />,
          content: (
            <ModalBody
              title=" No fue posible crear la póliza"
              message="Acaba de ocurrir un problema, lo sentimos."
            />
          ),
          onAccept: clearProcess,
          titleOnAccept: "Aceptar",
          withClass: "flex",
          showBtnAccept: true,
        }));
      }
    }
  };

  const handlePreviousStep = () => {
    setStep(4);
    setInfoPlanDevice(null);
    setClient(null);
  };
  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        <span>Confirmación</span>
      </h2>
      <div className="grid grid-cols-2 gap-10">
        <table className="table w-full text-secondary">
          <tbody>
            <tr>
              <td className="border-t-0">
                <h6 className="mb-4 font-medium text-buttonBlack">
                  Datos cliente{" "}
                </h6>
              </td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td
                className="border-b-0 border-t-0 pb-3 pt-1"
                style={{ width: "10px" }}
              >
                Nombre(s):
              </td>
              <td
                className="border-b-0 border-t-0 pb-3 pt-1 text-end"
                style={{ width: "10px" }}
              >
                {client?.name}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Apellido(s):</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {client?.lastName}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">
                Tipo de documento:
              </td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {client?.typeDocument?.name}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">
                Número de documento:
              </td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {client?.numberDocument}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Género:</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {client?.gender?.name}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Email:</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {client?.email}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">
                Número de celular
              </td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {client?.phone}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Ciudad</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {client?.city?.nombre}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Dirección</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {client?.address}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">
                Fecha de nacimiento
              </td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {client?.birthday.toISOString().split("T")[0]}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table w-full text-secondary">
          <tbody>
            <tr className="h-10">
              <td className="w-full" colSpan={1}>
                <h6 className="mb-4 font-medium text-buttonBlack">
                  Datos del dispositivo
                </h6>
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">
                Modelo del dispositivo:
              </td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {infoPlanDevice?.model?.device_name}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">Fabricante:</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {infoPlanDevice?.manufacturer.nombre}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0 pb-3 pt-1">IMEI:</td>
              <td className="border-b-0 border-t-0 pb-3 pt-1 text-end">
                {infoPlanDevice?.imei}
              </td>
            </tr>
            <tr>
              <td className="border-t-0">&nbsp;</td>
            </tr>
            <tr>
              <td className="border-t-0">&nbsp;</td>
            </tr>
            <tr>
              <td className="border-t-0">&nbsp;</td>
            </tr>
            <tr>
              <td className="border-t-0">
                <h6 className="font-medium text-buttonBlack">Datos del plan</h6>
              </td>
              <td className="border-t-0">&nbsp;</td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0">Nombre del plan</td>
              <td className="border-b-0 border-t-0 text-end">
                {infoPlanDevice?.plan?.name}
              </td>
            </tr>
            <tr>
              <td className="border-b-0 border-t-0">Valor de cobertura</td>
              <td className="border-b-0 border-t-0 text-end">
                {infoPlanDevice?.valuePolicie
                  .toString()
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-5 flex w-full justify-between">
        <PrimaryButton
          title="Atrás"
          type="button"
          className={"mt-5 rounded-3xl border px-5 py-1 text-textBlack"}
          style={{ borderColor: "#0a0a0a" }}
          onClick={() => handlePreviousStep()}
        />
        <PrimaryButton
          title="Asegurar"
          className={
            "mt-5 rounded-3xl bg-buttonBlack px-5 py-1 text-sm font-normal text-whiteSoft"
          }
          onClick={() => onSubmit()}
          loading={isLoading}
        />
      </div>
    </section>
  );
};

export default ReviewComponentStep;
