import { AxiosResponse } from "axios";

import { microserviceApiRoutes } from "../../microservice-api.routes";
import { microserviceApiAxios } from "../../microservice-api.axios";

import {
  IListDocumentsCoveragesPlanReqParams,
  IListDocumentsCoveragesPlanRes,
  IListDocumentsUploadReq,
  IListDocumentsUploadRes,
  IListDocumentsUploadTotalDamageReq,
  IListDocumentsUploadTotalDamageRes,
} from "./document-service.interface";

export const listDocumentsCoveragesPlanDeviceUserInsurrance = ({
  coverageId,
}: IListDocumentsCoveragesPlanReqParams) => {
  const url = microserviceApiRoutes.v1.claim.documents.list(coverageId);

  return microserviceApiAxios
    .get<Error, AxiosResponse<IListDocumentsCoveragesPlanRes>>(url, {})
    .then((res) => res.data);
};

export const listDocumentsUploadTotalDamage = ({
  stageID,
}: IListDocumentsUploadTotalDamageReq) => {
  const url =
    microserviceApiRoutes.v1.claim.documents.uploadTotalDamage(stageID);

  return microserviceApiAxios
    .get<Error, AxiosResponse<IListDocumentsUploadTotalDamageRes>>(url)
    .then((res) => res.data);
};

export const uploadDocumentsStatesClaims = ({
  stageID,
  data,
}: IListDocumentsUploadReq) => {
  const url = microserviceApiRoutes.v1.claim.documents.uploadDocuments(stageID);

  return microserviceApiAxios
    .post<Error, AxiosResponse<IListDocumentsUploadRes>>(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
};
