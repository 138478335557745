import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Input from "../../../../components/inputs/Input";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { useCreateMiCeluContext } from "../provider/createMiCelu.provider";
import { useAuthLoginAgents } from "../../../../services/microservice/micelu/auth/use-auth-micelu";
import { useLoginUser } from "../../../../hooks/useLoginUser";

type IFormInput = {
  code: string;
};

const schemaCode2FA = yup.object({
  code: yup
    .string()
    .matches(/^\d+$/, { message: " Solo acepta números" })
    .required(" Requerido"),
});

const Code2FAStepComponent = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaCode2FA),
  });
  const { user, setStep, setUser } = useCreateMiCeluContext();
  const { login } = useLoginUser();
  const { mutateAsync: login2FA, isLoading } = useAuthLoginAgents();
  const [error, setError] = useState<string>("");

  const handlePreviousStep = () => {
    setStep(1);
    setUser(null);
  };

  const onSubmit = async (val: IFormInput) => {
    try {
      const res = await login2FA({
        email: user.email,
        password: user.password,
        sponsorID: "MICELU",
        otp: val.code,
      });

      login(res.data, false);
      setStep(3);
      setError("");
    } catch (error) {
      setError("Ocurrió un error inesperado");
    }
  };

  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        <span>Código de verificación</span>
      </h2>
      {error && <p className="text-md text-critical">{error}</p>}
      <div className="mb-10 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                classNameContainer="w-full mb-7"
                classNameInput="w-full"
                placeholder="Código de verifación"
                error={errors.code?.message}
                required
              />
            )}
          />
          <div className="flex w-full justify-between">
            <PrimaryButton
              title="Atrás"
              type="button"
              className={"mt-5 rounded-3xl border px-5 py-1 text-textBlack"}
              style={{ borderColor: "#0a0a0a" }}
              onClick={() => handlePreviousStep()}
            />
            <PrimaryButton
              title="Verificar código"
              className={
                "mt-5 rounded-3xl bg-buttonBlack px-5 py-1 text-sm font-normal text-whiteSoft"
              }
              loading={isLoading}
            />
          </div>
        </form>
      </div>
    </section>
  );
};

export default Code2FAStepComponent;
