import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Input from "../../../../components/inputs/Input";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { useAuthLoginAgents } from "../../../../services/microservice/micelu/auth/use-auth-micelu";
import { useCreateMiCeluContext } from "../provider/createMiCelu.provider";
import { useLoginUser } from "../../../../hooks/useLoginUser";

type IFormInput = {
  email: string;
  password: string;
};

const schemaLogin = yup
  .object({
    email: yup.string().email().required(" Requerido"),
    password: yup.string().required(" Requerido"),
  })
  .required();

const LoginStepComponent = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaLogin),
  });
  const { mutateAsync: loginAgents, isLoading } = useAuthLoginAgents();
  const { setStep, setUser } = useCreateMiCeluContext();
  const [error, setError] = useState<string>("");
  const { login } = useLoginUser();

  const onSubmit = async ({ password, email }: IFormInput) => {
    loginAgents({ email, password, sponsorID: "MICELU" })
      .then((res) => {
        if (res) {
          login(res.data, false);
          setStep(3);
        }
      })
      .catch((err) => {
        if (
          err?.status === 400 &&
          err?.response?.data.error.message === "OTP required"
        ) {
          setUser({ password, email });
          setStep(2);
        } else {
          setError("Credenciales incorrectas");
        }
      });
  };

  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        <span className="text-buttonBlack">Iniciar sesión</span>
      </h2>
      {error && <p className="text-md text-critical">{error}</p>}
      <div className="mb-10 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                classNameContainer="w-full mb-7"
                classNameInput="w-full"
                placeholder="Email"
                error={errors.email?.message}
                required
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                classNameContainer="w-full my-2"
                classNameInput="w-full"
                placeholder="Contraseña"
                error={errors.password?.message}
                required
                type="password"
              />
            )}
          />
          <PrimaryButton
            title="Iniciar sesión"
            className={
              "mt-5 rounded-3xl bg-buttonBlack px-5 py-1 text-sm font-normal text-whiteSoft"
            }
            loading={isLoading}
          />
        </form>
      </div>
    </section>
  );
};

export default LoginStepComponent;
