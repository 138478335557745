import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import Input from "../../../../../components/inputs/Input";
import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
import { useStepCreateEnelContext } from "../stepper.provider";
import { useRecoveryEmailService } from "../../../../../services/microservice/enel/auth/use-auth";
import { useModalContext } from "../../../../../providers/modal/modal.provider";
import ERRORS from "../../../components/errors";
import ModalBodyRecovery from "../../components/ModalBodyRecovery";

const schemaLogin = yup
  .object({
    username: yup
      .string()
      .matches(/^\d+$/, { message: " Requerido" })
      .required(" Requerido"),
  })
  .required();

const RecoverStepComponent = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaLogin),
  });
  const { setCurrentStep } = useStepCreateEnelContext();
  const { setShow } = useModalContext();
  const { mutateAsync: recovery, isLoading } = useRecoveryEmailService();
  const [error, setError] = useState<string>("");

  const onSubmit = async (val) => {
    try {
      const res = await recovery({
        username: val.username.trim(),
        sponsorID: "ENEL",
      });
      if (res) {
        setShow((prevState) => ({
          ...prevState,
          visible: true,
          content: <ModalBodyRecovery email={res.data.email} />,
          showBtnDecline: true,
          titleOnDecline: "Aceptar",
          onDecline: () => handleBackLogin(),
        }));
      }
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 404) {
        setError(ERRORS.UNAUTHORIZED);
      } else {
        setError(ERRORS.INTERNAL);
      }
    }
  };

  const handleBackLogin = () => {
    setShow(() => ({
      header: <></>,
      content: <></>,
      visible: false,
      showBtnAccept: false,
      showBtnDecline: false,
    }));
    setCurrentStep(1);
  };

  return (
    <section className="w-full">
      <h2 className="mb-5 text-center text-3xl font-bold">
        <span style={{ color: "#461E7D" }}>Recuperación </span>
      </h2>
      {error && <p className="text-md text-critical">{error}</p>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              classNameContainer="w-full my-2"
              classNameInput="w-full"
              placeholder="Número de identificación"
              error={errors.username?.message}
              required
            />
          )}
        />
        <div className="flex w-full justify-end">
          <PrimaryButton
            title="RECUPERAR"
            className={
              "mt-5 rounded-3xl px-5 py-1 text-sm font-normal text-whiteSoft"
            }
            loading={isLoading}
            style={{ backgroundColor: "#461E7D" }}
          />
        </div>
      </form>
    </section>
  );
};

export default RecoverStepComponent;
