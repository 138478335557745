import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Case, Switch, Default, When } from "react-if";
import { FormControlLabel, Radio, RadioGroup, Skeleton } from "@mui/material";
import { useQueryClient } from "react-query";

import DropdownFilter from "../../../inputs/DropdownFilter";
import { listCityInsurrance } from "../../../../services/microservice/claim/city/city-service";
import { ICitiesClaim } from "../../../../services/microservice/claim/types/claims";
import PrimaryButton from "../../../buttons/PrimaryButton";
import { useStepContext } from "../stepper.provider";
import Input from "../../../inputs/Input";
import { useListDocumentsUploadTotalDamage } from "../../../../services/microservice/claim/documents/use-documents";

interface ICustomStepProps {
  colorPrimary: string;
}

type IFormInput = {
  city: {
    id: number;
    nombre: string;
    departamento: {
      nombre: string;
    };
  };
  address: string;
  pickUp: boolean;
};

const messageRequired = " Requerido";

const UploadStatesDocumentsStep = ({ colorPrimary }: ICustomStepProps) => {
  const { reclamation, setCurrentStep, setUploadDocForm } = useStepContext();
  const [cities, setCities] = useState<ICitiesClaim[]>([]);
  const { data: Documents, isLoading } = useListDocumentsUploadTotalDamage(
    String(reclamation.current_stage.stage_order_id),
  );
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    setError,
    clearErrors,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const handlePreviusStep = () => {
    setCurrentStep((prevState) => prevState - 1);
    queryClient.removeQueries("claims/stage/documents");
  };

  const onSubmit: SubmitHandler<IFormInput> = (values) => {
    let documents = {};
    Object.entries(values).forEach((val) => {
      if (val[0] !== "city" && val[0] !== "address" && val[0] !== "pickUp") {
        documents = {
          [val[0]]: val[1],
          ...documents,
        };
      }
    });
    setUploadDocForm({
      city: values.city,
      pickUp: values.pickUp,
      address: values.address,
      documents,
    });
    setCurrentStep((prevState) => prevState + 1);
  };

  useEffect(() => {
    listCityInsurrance().then((res) => setCities(res.data.cities));
  }, []);

  const validateSize = (id, value) => {
    if (value.target.files.length > 0 && value.target.files[0].size > 4000000) {
      setError(`${id}`, {
        message: "Excediste el tamaño permitido de 4MB",
      });
    } else {
      clearErrors(`${id}`);
    }
  };

  return (
    <section>
      <h2 className="mb-5 text-center text-3xl font-bold">
        4.{" "}
        <span
          style={{
            color: colorPrimary,
          }}
        >
          Información
        </span>{" "}
        para cargar
      </h2>

      <p className="my-2 text-base text-critical">* Campos obligatorios</p>
      <div className="mb-10 w-full">
        <Switch>
          <Case condition={isLoading}>
            <Skeleton variant="text" width={"100%"} height={"14rem"} />
          </Case>
          <Default>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DropdownFilter
                {...register("city", {
                  required: { value: true, message: messageRequired },
                })}
                options={cities || []}
                label="nombre"
                placeholder="Seleccione ciudad"
                error={errors["city"] && String(errors["city"]?.message)}
                required
                value={watch("city")}
                onChange={(e) => setValue("city", e)}
              />
              <Input
                {...register("address", {
                  required: { value: true, message: messageRequired },
                })}
                classNameContainer="w-full mb-3 "
                classNameInput="w-full "
                placeholder="Dirección de residencia"
                error={errors["address"] && String(errors["address"]?.message)}
                onChange={(e) => setValue("address", e.target.value)}
                required
              />
              <When condition={watch("city")?.nombre !== "Bogotá"}>
                <p className="text-md">
                  ¿Solicita recogida?{" "}
                  <span className="text-center text-3xl text-critical">*</span>{" "}
                  {errors["pickUp"] && (
                    <span className="text-critical">
                      {String(errors["pickUp"]?.message)}
                    </span>
                  )}
                </p>

                <RadioGroup
                  className="mb-2"
                  {...register("pickUp")}
                  defaultValue={"FALSE"}
                >
                  <FormControlLabel
                    value="TRUE"
                    control={<Radio />}
                    label="Sí, deseo que recojan el equipo por mí."
                  />
                  <FormControlLabel
                    value="FALSE"
                    control={<Radio />}
                    label="No, llevaré o enviaré el equipo por mi cuenta."
                  />
                </RadioGroup>
              </When>
              {Documents?.data.documents.map((doc) => (
                <>
                  <div className="mb-2 ml-1">
                    <p className="text-md font-normal">
                      {doc.nombre_documento_save}
                      {doc.required && (
                        <>
                          <span className="text-critical"> * </span>
                          {errors[doc.id] && (
                            <span className="text-critical">
                              {String(errors[doc.id]?.message)}
                            </span>
                          )}
                        </>
                      )}
                    </p>
                  </div>
                  <div className="relative inline-block w-full">
                    <label
                      className="left-1 z-10 inline-block h-10 w-full overflow-hidden text-ellipsis whitespace-nowrap rounded-md bg-gray px-3 py-2 text-textBlack after:absolute after:right-0 after:top-0 after:block after:h-10 after:rounded-r-md after:bg-buttonBlack after:px-2 after:pt-2 after:text-center after:align-middle after:text-white after:content-['Seleccionar']"
                      htmlFor={`${doc.id}`}
                    >
                      {watch(`${doc.id}`)
                        ? watch(`${doc.id}`).length > 0 &&
                          watch(`${doc.id}`)[0].name
                        : null}
                    </label>

                    <input
                      {...register(`${doc.id}`, {
                        required: {
                          value: doc.required,
                          message: "Requerido",
                        },
                        onChange: (event) => validateSize(`${doc.id}`, event),
                      })}
                      id={`${doc.id}`}
                      type="file"
                      accept=".pdf, .jpg, .jpeg"
                      className="absolute left-0 top-0 -z-10 w-full opacity-0"
                    />
                    <div>
                      <small className="mt-1 text-sm text-textBlack">
                        Upload max size 4MB (PDF o JPG).
                      </small>
                    </div>
                  </div>
                </>
              ))}
              <div className="mt-4 flex justify-between">
                <PrimaryButton
                  title="Atras"
                  className="rounded-2xl border px-5 text-textBlack"
                  onClick={() => handlePreviusStep()}
                  type="button"
                />
                <PrimaryButton
                  type="submit"
                  title="Siguiente"
                  className="rounded-2xl bg-buttonBlack px-5 text-white"
                />
              </div>
            </form>
          </Default>
        </Switch>
      </div>
    </section>
  );
};

export default UploadStatesDocumentsStep;
