import { AxiosResponse } from "axios";

import { microserviceApiAxios } from "../../microservice-api.axios";
import { microserviceApiRoutes } from "../../microservice-api.routes";
import {
  IGetManufacturersServiceRes,
  IGetModelsServiceReq,
  IGetModelsServiceRes,
  IGetPoliciesDevicesBySponsorReq,
  IGetPoliciesDevicesBySponsorRes,
  IVerifyDeviceBySponsorReq,
} from "./device-service.interface";

export const getModelsBySponsor = (data: IGetModelsServiceReq) => {
  const { manufacturer, sponsorID } = data;
  const url = microserviceApiRoutes.v1.devices.models.get();
  return microserviceApiAxios
    .post<
      Error,
      AxiosResponse<IGetModelsServiceRes>
    >(url, { manufacturer }, { params: { sponsorID } })
    .then((res) => res.data);
};

export const getManufacturersBySponsor = (sponsorID: string) => {
  const url = microserviceApiRoutes.v1.devices.manufacturers.get();
  return microserviceApiAxios
    .get<
      Error,
      AxiosResponse<IGetManufacturersServiceRes>
    >(url, { params: { sponsorID } })
    .then((res) => res.data);
};

export const getPoliciesBySponsor = (data: IGetPoliciesDevicesBySponsorReq) => {
  const url = microserviceApiRoutes.v1.devices.policies.get();
  return microserviceApiAxios
    .post<Error, AxiosResponse<IGetPoliciesDevicesBySponsorRes>>(url, data)
    .then((res) => res.data);
};

export const verifyDeviceBysponsor = (data: IVerifyDeviceBySponsorReq) => {
  const url = microserviceApiRoutes.v1.devices.verify();
  return microserviceApiAxios.post(url, data).then((res) => res.data);
};
