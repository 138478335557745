import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { IInfoPlanDevice, IClientInfo } from "./micelu.provider.interfaces";

interface IProps {
  children: ReactNode;
}

interface IAuthAgent {
  email: string;
  password: string;
}

type IContext = {
  step: number;
  user: IAuthAgent | null;
  client: IClientInfo | null;
  infoPlanDevice: IInfoPlanDevice | null;
  setStep: Dispatch<SetStateAction<number>>;
  setUser: Dispatch<SetStateAction<IAuthAgent | null>>;
  setInfoPlanDevice: Dispatch<SetStateAction<IInfoPlanDevice | null>>;
  setClient: Dispatch<SetStateAction<IClientInfo | null>>;
};

export const CreateMiCeluContext = React.createContext<IContext>(
  {} as IContext,
);
export const useCreateMiCeluContext = () =>
  useContext<IContext>(CreateMiCeluContext);

const CreateMiCeluProvider = ({ children }: IProps) => {
  const [step, setStep] = useState<number>(1);
  const [user, setUser] = useState<IAuthAgent | null>(null);
  const [infoPlanDevice, setInfoPlanDevice] = useState<IInfoPlanDevice | null>(
    null,
  );
  const [client, setClient] = useState<IClientInfo | null>(null);
  const context: IContext = useMemo(
    () => ({
      step,
      user,
      infoPlanDevice,
      client,
      setStep,
      setUser,
      setInfoPlanDevice,
      setClient,
    }),
    [step, user, infoPlanDevice, client],
  );
  return (
    <CreateMiCeluContext.Provider value={context}>
      {children}
    </CreateMiCeluContext.Provider>
  );
};

export default CreateMiCeluProvider;
